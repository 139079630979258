import "../scss/main.scss";
import '../favicon.ico';
import '../send_mail.php';

document.addEventListener("DOMContentLoaded", () => {

  const mail_popup = document.querySelector('.mail_popup');
  const mail_popup_message = document.querySelector('.mail_popup_message');
  const close_popup = document.querySelector('.close_popup');
  const urlParams = new URLSearchParams(window.location.search);

  if (urlParams.get('email')) {
    mail_popup.classList.add('visible');

    if (urlParams.get('email') == 1) {
      mail_popup_message.innerHTML = "<h2>Mail na adres: <br><span>kontakt@poltrans-zywiec.pl</span><br> wysłany pomyślnie</h2>"
    } else if (urlParams.get('email') == 2) {
      mail_popup_message.innerHTML = "<h2>Nie udało się wysłać maila!</h2>"
    }
  }
  if (close_popup) {
    close_popup.addEventListener('click', () => {
      mail_popup.classList.remove('visible');
    })
  }


  const smallImgs = document.querySelectorAll(".small-img");
  const bigImg = document.querySelector("#big-img");

  const hamburger = document.querySelector(".hamburger");
  const mainNav = document.querySelector(".main-nav");

  smallImgs.forEach(img => {
    img.addEventListener("click", function (e) {
      smallImgs.forEach(img => img.classList.remove('clicked'));

      bigImg.classList.add('animated');
      setTimeout(() => {
        bigImg.src = img.firstElementChild.src;
      }, 500);
      img.classList.add('clicked');
      setTimeout(() => {
        bigImg.classList.remove('animated');
      }, 1000);

    });
  });

  hamburger.addEventListener("click", () => {
    hamburger.classList.toggle("active");

    if (hamburger.classList.contains("active")) {
      mainNav.classList.add("active");
    } else {
      mainNav.classList.remove("active");
    }
  });

  // close certif popup
  const close_pcertip_popup = document.querySelectorAll('.fas.fa-times')
  close_pcertip_popup.forEach(x => {
    x.addEventListener('click', function (e) {
      smallImgs.forEach(img => img.classList.remove('clicked'));
    })
  })

  // Add smooth scrolling to all links
  $(".nav_a").on("click", function (event) {
    // hide navigation after btn click
    hamburger.classList.remove("active");
    mainNav.classList.remove("active");

    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {
      // Prevent default anchor click behavior
      event.preventDefault();

      // Store hash
      var hash = this.hash;

      // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
      $("html, body").animate({
          scrollTop: $(hash).offset().top
        },
        700,
        function () {
          // Add hash (#) to URL when done scrolling (default click behavior)
          window.location.hash = hash;
        }
      );
    } // End if
  });
});